#learns2header {
  /*
  top: 0;
  position: fixed;
  */
  width: 100%;
  margin-bottom: 15px;
  background-color: #000d1a;
}

#uploadCallout {
  background-color: rgb(45, 64, 78);
}

.home-grid {
  display: grid;
  justify-items: center;
}

.home-grid-item {
  width: 90%;
}

.analysis-grid {
  display: grid;
  justify-items: center;
}

.analysis-grid-item {
  width: 90%;
  margin-top: 10px;
}

.analysis-player-card {
  margin-top: 10px;
}

.analysis-profile-grid {
  display: grid;
  justify-items: left;
  grid-template-columns: 1fr 2fr;
}

.player-card-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.tr-high-confidence {
  background-color: #0D8050;
}
.tr-med-confidence {
  background-color: #BF7326;
}
.tr-low-confidence {
  background-color: #FF7373;
}

.upload-prompt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-prompt-item {
  display: flex;
  padding: 1em;
}

.s2-grid {
  display: grid;
  grid-template-columns: minmax(250px, 25vw) 75vw;
  width: 100vw;
  height: 100vh;
}



.s2-grid-left-menu {
  background-color: rgb(45, 45, 78);
}

.s2-grid-body {
  background-color: rgb(45, 64, 78);
}

.left-menu {
  height: 100%;
}

.dropzone-container {
  height: 150px;
  /*background-color: rgb(230, 197, 197);*/
  /*background-color: black;*/
  border-radius: 8px;
	/*background: linear-gradient(1deg, #48aff0, #244860, #48aff0, #244860);*/
	animation: gradient 15s ease infinite;
}

.gradient-primary {
	background: linear-gradient(90deg, #48aff0, #244860);
	background-size: 200% 200%;
}

.gradient-thinking {
	background: linear-gradient(90deg, #48aff0, darkgreen);
	background-size: 200% 200%;
}

html {
  /*background-color: #000d1a;*/
  background-color: rgb(45, 64, 78);
  /*background-color: lightgrey;*/
}

.my-form {
  display: grid;
  grid-template-columns: 200px 1fr;
}

.grid-container {
  display: grid
}

.player-search {
  display: inline-block;
  width: 300px;
}

.labeler-footer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}